<template>
  <div class="progress blue">
    <span class="progress-left">
      <span v-if="value > 50" class="progress-bar" :style="{ transform: `rotate(${value > 50 ? (value - 50) * 3.6 : 0}deg)` }"></span>
    </span>
    <span class="progress-right">
      <span class="progress-bar" :style="{ transform: `rotate(${value <= 50 ? (value * 3.6) : 180}deg)` }"></span>
    </span>
    <div class="progress-value">{{ value }}%</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped>

.progress {
  width: 30px;
  height: 30px !important;
  float: left;
  background: none;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 3px;
  border-style: solid;
  position: absolute;
  top: 0;
  transition: transform 0.5s ease-out;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: transform 0.5s ease-out;
}
.progress .progress-value {
  border-radius: 50%;
  font-size: 10px;
  color: black;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 15%;
  z-index: 2;
}
.progress.blue .progress-bar {
  border-color: #305BF2;
}
</style>